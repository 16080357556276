import $ from 'jquery'
import 'jquery-waypoints/waypoints'
import Bowser from 'bowser'
import 'magnific-popup'
import { navigate } from "gatsby"

export function updateDocument() {


  // Global Variables
  let PWS;
  PWS = typeof (PWS) === 'undefined' ? {} : PWS;
  PWS.windowScrollTop = 0;
  PWS.platformType = Bowser.parse(window.navigator.userAgent).platform.type;
  PWS.isTablet = PWS.platformType === 'tablet';
  PWS.isPhone = PWS.platformType === 'mobile';
  PWS.isMobile = PWS.isTablet || PWS.isPhone;
  PWS.mql = window.matchMedia('(min-width: 811px)');
  PWS.isTabletScreenSize = document.documentElement.clientWidth < 1280 && document.documentElement.clientWidth >= 768;
  PWS.isMobileScreenSize = document.documentElement.clientWidth < 768;
  PWS.is_home_page = $('#px-layout').hasClass('main-page');
  const currentLocation = window.location.pathname.replace(/\/$/, '');


  // Platform Detection
  if (navigator.appVersion.indexOf("Win") !== -1)
    $('form.trial #trial_windows').prop('checked', true);
  if (navigator.appVersion.indexOf("Mac") !== -1)
    $('form.trial #trial_mac').prop('checked', true);


  // Disable bounce scroll
  var no_bounce_scroll = $('#px-layout').hasClass('no-bounce-scroll');
  if (no_bounce_scroll)
    $('html').addClass('no-bounce-scroll');
  var $window = $(window),
    $window_element = (!no_bounce_scroll) ? $window : $('html, body'),
    $scroll_element = (!no_bounce_scroll) ? $window : $('body');


  // Set variable for viewport for mobile devices
  function setVh() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setVh();
  window.addEventListener('orientationchange', function () {
    setTimeout(function () {
      setVh();
    }, 500);
  });


  // Sticky Navigation on scroll
  function shrinkNav() {

    var shrink_factor = (PWS.is_home_page) ? 0 : 200;

    if ($window.scrollTop() > shrink_factor) {
      if (!$('.page-header').hasClass('is-shrink')) {
        $('.page-header').addClass('is-shrink');
        $('.mob-nav').addClass('is-shrink');
      }
    }
    else {
      $('.page-header').removeClass('is-shrink');
      $('.mob-nav').removeClass('is-shrink');
    }

  }

  // Sticky Sub Navigation on scroll
  function shrinkSubNav() {

    if ($('.internal-sub-header').length) {

      if ($(window).scrollTop() > 80) {
        if (!$('.internal-sub-header').hasClass('is-shrink'))
          $('.internal-sub-header').addClass('is-shrink');
      }
      else
        $('.internal-sub-header').removeClass('is-shrink');

    }

  }


  // Scroll to a page element and fire a callback.
  function scrollToElement(duration, cb, element) {

    if (typeof (duration) === 'undefined')
      duration = 1000;

    if (typeof (cb) === 'undefined')
      cb = function () { };

    $('html, body').animate({
      scrollTop: $(element).offset().top - 100
    }, duration, function () {
      cb(this);
    });

  }


  // Hide header and footer if page is loaded in an iframe
  function checkIframe() {
    if (window.self !== window.top) {
      $('header').css('display', 'none');
      $('footer').css('display', 'none');
      $('.page-container').css('padding-top', 0);
    }
  }


  // Scroll and open video model
  function scrollToVideoSection(duration, cb, videoSectionId) {

    if (typeof (duration) === 'undefined')
      duration = 1000;

    if (typeof (cb) === 'undefined')
      cb = function () { };

    var videoContainerId = videoSectionId || 'video';

    $('html,body').animate({
      scrollTop: $('#' + videoContainerId).offset().top - 50
    }, duration, function () {
      cb(this);
    });

  }


  // Inialize Video in iframe by click the button
  function initializeVideos() {
    $('.video-target, .smartslides-video-target').each(function (idx, obj) {
      if ($(obj).hasClass('-with-timecode')) {
        let URL = $(obj).attr('href');
        let params = URL.slice(URL.indexOf('?'), URL.length);
        params = params.replace('?', '');
        params = params.split('&');
        let id = params[0].slice(params[0].indexOf('=') + 1, params[0].length);
        let startTime = params[1].slice(params[1].indexOf('=') + 1, params[1].length);
        startTime = startTime.slice(0, startTime.indexOf('s'));

        $(obj).magnificPopup({
          type: 'iframe',
          iframe: {
            markup:
              '<div class="mfp-iframe-scaler">' +
              '<div class="mfp-title"></div>' +
              '<div class="mfp-close"></div>' +
              '<iframe class="mfp-iframe" frameborder="0" src="https://www.youtube.com/embed/Mhpss4saoDQ?si=2wuTPJyBUoKbZZns&amp;start=34" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>' +
              '</div>'
          },
          callbacks: {
            open: function (e) {
              $('.mfp-iframe').attr('src', `https://www.youtube.com/embed/${id}?start=${startTime}`);
            },
            markupParse: function (template, values, item) {
              var $item = $(item.el);
              values.title = $item.attr('title');
            },
            close: function () {
              //Don't scrollback
              this._lastFocusedEl = null;
            }
          },
          fixedContentPos: false,
          fixedBgPos: true,
          overflowY: 'auto',
          closeBtnInside: true,
          preloader: false,
          midClick: true,
          removalDelay: 300,
          mainClass: 'my-mfp-zoom-in'
        });
      }

      else {
        $(obj).magnificPopup({
          type: 'iframe',
          iframe: {
            markup:
              '<div class="mfp-iframe-scaler">' +
              '<div class="mfp-title"></div>' +
              '<div class="mfp-close"></div>' +
              '<iframe class="mfp-iframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>' +
              '</div>'
          },
          callbacks: {
            markupParse: function (template, values, item) {
              var $item = $(item.el);
              values.title = $item.attr('title');
            },
            close: function () {
              //Don't scrollback
              this._lastFocusedEl = null;
            }
          },
          fixedContentPos: false,
          fixedBgPos: true,
          overflowY: 'auto',
          closeBtnInside: true,
          preloader: false,
          midClick: true,
          removalDelay: 300,
          mainClass: 'my-mfp-zoom-in'
        });
      }
    });
  }


  // DOM loaded
  $(document).ready(function () {


    // Initialize functions
    checkIframe();
    shrinkNav();
    shrinkSubNav();


    // Mobile Navigation

    // Toggle Mobile Navigation
    var
      $mob_menu = $('.mob-nav'),
      $mob_menu_trigger = $mob_menu.find('.mob-nav__trigger');

    $mob_menu_trigger.removeClass('-first-open');

    $mob_menu_trigger.on('click.pxtrigger', function () {
      if ($mob_menu.hasClass('-closed')) {
        $mob_menu.removeClass('-closed').addClass('-open');
        $('html').addClass('-no-scroll');
      }
      else if ($mob_menu.hasClass('-open')) {
        $mob_menu.removeClass('-open').addClass('-closed');
        $('html').removeClass('-no-scroll');
      }
    });

    // Mobile Navigation (Accordion)
    $('.mob-nav__navigation__item__title').each(function () {

      var
        $trigger = $(this),
        $item = $trigger.closest('.mob-nav__navigation__item');

      $trigger.on('click.pxtrigger', function () {
        if ($item.hasClass('-closed')) {
          $('.mob-nav__navigation__item').removeClass('-open').addClass('-closed');
          $item.removeClass('-closed').addClass('-open');
        }
        else if ($item.hasClass('-open'))
          $item.removeClass('-open').addClass('-closed');
      });

    });

    // Close Mobile Navigation ease on link click
    $('.mob-nav__navigation__item__content a').not('.active').not('.access').each(function () {
      $(this).on('click.pxtrigger', function ($e) {
        var href = $(this).attr('href');
        $e.preventDefault();

        if ($(this).hasClass('mob-nav__navigation__item__content__lang-link')) {
          $('.mob-nav__navigation__item__title.-language').attr('data-selected-language', $(this).attr('data-language-id'));
        }

        $mob_menu.removeClass('-open').addClass('-closed');
        $('html').removeClass('-no-scroll');

        setTimeout(function () {
          $mob_menu_trigger.addClass('-first-open');
          navigate(href);
        }, 700);
      });
    });

    // Open current accordion group
    $('.mob-nav__navigation__item__content a.active').closest('.mob-nav__navigation__item').removeClass('-closed').addClass('-open');

    // Mobile SubNav
    $('.subnav-mobile__menu').each(function () {

      var
        $menu = $(this),
        $current = $menu.find('.subnav-mobile__menu__current');

      $current.on('click.pxtrigger', function () {
        if ($menu.hasClass('-closed'))
          $menu.removeClass('-closed').addClass('-open');
        else if ($menu.hasClass('-open'))
          $menu.removeClass('-open').addClass('-closed');
      });

    });

    // Close Mobile SubNav ease on link click
    $('.subnav-mobile__menu__dropdown a').not('.active').each(function () {
      $(this).on('click.pxtrigger', function ($e) {
        var href = $(this).attr('href');
        $e.preventDefault();
        $('.subnav-mobile__menu').removeClass('-open').addClass('-closed');
        setTimeout(function () {
          $mob_menu_trigger.addClass('-first-open');
          navigate(href);
        }, 700);
      });
    });

    $('body').on('click.pxtrigger', function (event) {
      if ($(event.target).closest('.subnav-mobile__menu').length === 0)
        $('.subnav-mobile__menu').removeClass('-open').addClass('-closed');
    });

    $(document).on('keyup.pxtrigger', function (e) {
      if (e.keyCode === 27) {
        $('.subnav-mobile__menu').removeClass('-open');
        $mob_menu.removeClass('-open').addClass('-closed');
        $('html').removeClass('-no-scroll');
      }
    });


    // Hidden video - init
    initializeVideos();


    if (
      !currentLocation.endsWith('/smartalbums') &&
      !currentLocation.endsWith('/smartalbums/')
    ) {
      // Watch video link on the new page
      $('.js-watch-video').click(function (evt) {
        evt.preventDefault();
        $('.video-target').magnificPopup('open');
      });
    }


    // Blog and SA Tutorials filter bar
    $('.filter-bar li span').unbind().click(function () {
      if ($window.width() < 800) {

        $('.filter-bar li.active').removeClass('active');
        $(this).parent().addClass('active').parent().addClass('is-open');
        return false;

      }
    });
    $('body').on('click.pxtrigger', function () {
      $('.filter.is-open').removeClass('is-open');
    });


    // Check if element is on the screen
    $.fn.isOnScreen = function () {

      var win = $(window);
      var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft()
      };

      viewport.bottom = viewport.top + win.height();

      var bounds = this.offset();
      bounds.bottom = bounds.top + this.outerHeight();

      return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));

    };


    // Parallax function
    function parallax(pageClass, wrapperClass, content, contentSpeed, opacitySpeed) {

      var wrapper = $(wrapperClass);

      if ((PWS.mql.matches) && !PWS.isTablet && ($(pageClass).length)) {

        if (wrapper.isOnScreen()) {

          var initialTop = wrapper.offset().top - 140;
          var contentBlock = wrapper.find(content);
          var moveBottom = (PWS.windowScrollTop - initialTop) * contentSpeed;
          var changeOpacity = (initialTop - PWS.windowScrollTop) * opacitySpeed;

          contentBlock.css({
            "transform": "translateY(" + moveBottom + "px)",
            "opacity": 1 + changeOpacity / 100
          });

        }

      }

    }


    // On Scroll
    $window.on('scroll.pxtrigger', function () {
      shrinkNav();
      shrinkSubNav();
      PWS.windowScrollTop = $(this).scrollTop();
      parallax('.smartslides-overview', '.banner-wrapper', '.content', 0.24, 0.14);
    });


    // Redirect mobile user when trying log in to smartslides
    $('body').on('click.pxtrigger', '.pss-deny-mobile-access', function (e) {

      var that = $(this);

      if (PWS.isMobile) {

        e.preventDefault();
        e.stopPropagation();

        if (that.data('px-redirect-timeout'))
          clearTimeout(that.data('px-redirect-timeout'));

        window.location.href = '/smartslides/mobile-smartslides-access/';
        return false;

      }

      return true;

    });


    // Redirect mobile user when trying log in to PSF
    $('body').on('click.pxtrigger', '.psf-deny-mobile-access', function (e) {

      var that = $(this);

      if (PWS.isMobile) {

        e.preventDefault();
        e.stopPropagation();

        if (that.data('px-redirect-timeout'))
          clearTimeout(that.data('px-redirect-timeout'));

        window.location.href = '/galleries/mobile-galleries-access/';
        return false;
      }

      return true;

    });


    // Parallax (another function)
    if ($('[data-role="parallax-parent"]').length > 0) {

      function parallaxHandler(type) {

        var
          scroll_top = $scroll_element.scrollTop(),
          $items = $('[data-role="parallax-item"]');

        $items.each(function () {

          var
            $e = $(this),
            $parent = $e.closest('[data-role="parallax-parent"]'),
            depth = parseFloat($e.attr('data-depth')),
            opacity = $e.attr('data-change-opacity-to'),
            on_mobile = $e.attr('data-parallax-on-mobile'),
            parallax_type = $e.attr('data-type'),
            reverse = $e.attr('data-reverse'),
            breakpoint = (typeof $e.data('breakpoint') === 'undefined') ? false : parseFloat($e.data('breakpoint')),
            movement;

          var do_parallax = (!PWS.isMobileScreenSize || (PWS.isMobileScreenSize && on_mobile !== 'disabled')) &&
            (document.documentElement.clientWidth > breakpoint) &&
            scroll_top >= ($parent.offset().top - $parent.innerHeight() - $(window).height()) &&
            scroll_top <= ($parent.offset().top + $parent.innerHeight() + $(window).height() / 2);

          if (PWS.isMobileScreenSize && $e.attr('data-mobile-depth'))
            depth = parseFloat($e.attr('data-mobile-depth'));

          if (do_parallax) {

            if (!parallax_type) {

              movement = -(scroll_top - $parent.offset().top) / 10 * depth;
              opacity = (opacity) ? 1 - (scroll_top - $parent.offset().top) / $window.height() : 1;

              if ($e.attr('data-stop')) {
                if (movement >= parseFloat($e.attr('data-stop'))) {
                  $e.css({
                    'transform': 'translate3d(0, ' + movement + 'px, 0)',
                    'opacity': opacity
                  });
                }
              }
              else {
                $e.css({
                  'transform': 'translate3d(0, ' + movement + 'px, 0)',
                  'opacity': opacity
                });

                if ($e.attr('data-blur-on-scroll') === 'yes') {
                  $e.css({
                    'filter': 'blur(' + (movement / 5 * -1) + 'px)'
                  });

                  if (scroll_top <= 1 && $('.main-page__hero').hasClass('-stop-init-animation')) {
                    $e.css({
                      'filter': 'blur(0px)'
                    });
                  }
                }
              }

            }

            else if (parallax_type === 'opacity') {

              if (PWS.isMobileScreenSize)
                depth = depth / 4;

              var
                window_height = ($(window).height() > 600 || PWS.isMobileScreenSize) ? $(window).height() / 5 : $e.height() - $(window).height() + $('.internal-sub-header').height();

              opacity = (scroll_top + window_height - $parent.offset().top) / ($scroll_element.height() / depth);

              if (reverse === 'yes')
                opacity = 1 - opacity;

              if (opacity < 0)
                opacity = 0;
              else if (opacity > 1)
                opacity = 1;

              $e.css({
                'opacity': opacity
              });

            }

            else if (parallax_type === 'background-position') {
              movement = (scroll_top / $parent.offset().top - 0.4) * 100;
              $e.css({
                'background-position': movement + '%, center',
              });
            }

            else if (parallax_type === 'move-to-right') {
              movement = (scroll_top - $parent.offset().top) / 10 + 20;
              $e.attr('data-progress', movement);
              $e.css({
                'transform': 'translate3d(' + movement + 'px, 0, 0)',
              });
            }

            else if (parallax_type === 'scale') {
              movement = scroll_top / ($parent.offset().top * 6) + 1;
              $e.css({
                'transform': 'scale(' + movement + ')',
              });
            }

          }

          else {

            $e.css({
              'transform': 'unset',
              'opacity': 1
            });

          }

        });

        if (type === 'init')
          $('[data-role="parallax-parent"]').addClass('-ready');

      }
      parallaxHandler('init');

      $window_element.on('scroll.pxtrigger', function () {
        parallaxHandler('scroll');
      });

      window.addEventListener('orientationchange', function () {
        $('[data-role="parallax-parent"]').removeClass('-ready');
        setTimeout(function () {
          parallaxHandler('init');
        }, 250);
      });

    }


    // Tutorials: Scroll to featured video
    $('.to-featured-video').click(function () {
      scrollToVideoSection(800, function () {
        $('.general-video .video-target').magnificPopup('open');
      }, 'featured-video');
    });


    // Tutorials: Scroll to featured videoContainerId
    $('.to-single-video').click(function () {
      var obj = this;
      scrollToElement(800, function () {
        $(obj).parent().find('.video-target').magnificPopup('open');
      }, this);
    });


    // Show elements on scroll
    function showOnScroll() {

      var
        scroll_top = $scroll_element.scrollTop();

      if (!$('html').hasClass('-loading')) {

        $('[data-action="fade-in"]').each(function () {

          var
            $e = $(this),
            top = ($scroll_element.is($window)) ? $e.offset().top : $e.offset().top + scroll_top,
            timeout = parseFloat($e.attr('data-timeout')),
            point = (!PWS.isMobileScreenSize) ? top - $window.height() / 1.45 : top - $window.height() / 1.25;

          if (!PWS.isMobileScreenSize || $e.attr('data-on-mobile') === 'yes') {

            if (scroll_top >= point && scroll_top > 0) {

              if (timeout) {
                setTimeout(function () {
                  $e.addClass('-show');
                }, timeout);
              }
              else
                $e.addClass('-show');

            }

          }

        });

      }

    }
    showOnScroll();

    $window_element.on('scroll.pxtrigger', function () {
      showOnScroll();
    });


  });


  // Repair the Beacon on fullscreen change (third-part microservice Beacon UI fix)
  $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', function () {

    if ($('#beacon-container .BeaconContainer > iframe').length > 0) {

      var $beacon_frame = $('#beacon-container .BeaconContainer > iframe').contents();

      if (!$beacon_frame.find('.BeaconLayout__Navigation').hasClass('has-backIcon')) {

        // Ask
        if (!$beacon_frame.find('.c-BeaconContainerContent').hasClass('has-sticky-footer'))
          $beacon_frame.find('.BeaconLayout__Header').css('height', '152px');

        // Answers
        else if ($beacon_frame.find('.c-BeaconContainerContent').hasClass('has-sticky-footer'))
          $beacon_frame.find('.BeaconLayout__Header').css('height', '70px');

      }

      // Chat
      else
        $beacon_frame.find('.BeaconLayout__Header').css('height', '152px');

    }

  });


  // Loader on Pricing pages

  // Safari's JS caching issue fixing
  $(window).bind("pageshow", function (event) {
    if (event.originalEvent.persisted)
      window.location.reload()
  });


  // Send data to FP if any
  $(function () {
    if (localStorage.getItem('FP-email')) {
      var event = {
        event: 'SATrialConfirmation',
        email: localStorage.getItem('FP-email')
      };
      window.dataLayer.push(event);
      localStorage.removeItem('FP-email');
    }
  });


  // Link to open pop-up
  if ($('a[href*="#popup-"').length > 0) {
    $('a[href*="#popup-"').each(function () {
      let $e = $(this);
      let href = $e.attr('href');
      let newHref = href.slice(href.indexOf('#'), href.length);
      let id = newHref.slice('#popup-'.length, newHref.length);
      let $popup = $('#' + id);

      $e.attr('href', newHref);

      $e.on('click.pxtrigger', function (e) {
        $popup.addClass('-open');
        e.preventDefault();
      });
    });
  }

  // Close pop-up
  if ($('.popup').length > 0) {
    $('.popup').each(function () {
      let $e = $(this);

      $e.on('click.pxtrigger', function (e) {
        if ($(e.target).closest('.popup__content').length === 0) {
          $e.removeClass('-open');
        }
      });
    });

    $(document).on('keyup.pxtrigger', function (e) {
      if (e.key === 'Escape') {
        $('.popup').removeClass('-open');
      }
    });
  }


}
